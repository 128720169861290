import React from "react";
import illustration from "../../../assets/img/illustration/kgf.jpg";

function Workingprocess(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        <div className="illustration-img">
          <img style={{ borderRadius: "10px" }} src={illustration} alt="" />
        </div>
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">KGF ve Ticari Kredi Desteği</span>
                <h2 className="title">
                  KGF ve Ticari Kredi Finansmanı
                  <br />{" "}
                </h2>
              </div>
              <p
                className="mb-25"
                style={{ textIndent: "15px", textAlign: "justify" }}
              >
                Kredi Garanti Fonu, kurumsal bir kefalet kuruluşu olarak teminat
                yetersizliği nedeniyle çeşitli kredi ve destek imkânlarından
                yeterince yararlanamayan Küçük ve Orta Büyüklükteki İşletmelerin
                (KOBİ) ve KOBİ dışı işletmelerin, “müteselsil kefil” olmak
                suretiyle krediye erişimlerini sağlamaktadır.
              </p>

              <p style={{ textIndent: "15px", textAlign: "justify" }}>
                Ticari krediler, bankaların işletme sahipleri için verdiği,
                kullanırken çeşitli teminatlar gösterilmesi gereken kredilerdir.
                Ticari krediler ile bireysel kredileri ayıran en önemli fark;
                ticari kredi işletme sahiplerine (tüzel kişilere) sağlanırken
                bireysel krediler gerçek kişilere sağlanmaktadır.
              </p>
              <br></br>
              <p style={{ textIndent: "15px", textAlign: "justify" }}>
                Must Global olarak; KGF ve Ticari Kredi kullanmak isteyen tüm firmalar için güçlü stratejilerimizle gerekli danışmanlık hizmetini sağlamaktayız.
              </p>
             
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Workingprocess;
