import React from "react";
import illustration from "../../../assets/img/illustration/sgk.jpg";

function Blocks(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        <div className="illustration-img">
          <img style={{ borderRadius: "10px" }} src={illustration} alt="" />
        </div>
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">Sgk Teşvik Desteği</span>
                <h2 className="title">
                  SGK Teşvikleri <br />{" "}
                </h2>
              </div>
              <p
                className="mb-25"
                style={{ textIndent: "15px", textAlign: "justify" }}
              >
                Son yıllarda sürekli güncellenen sosyal güvenlik mevzuatı, bu
                alanda şirketlere önemli fırsatlar getirirken, diğer yandan
                karmaşık yapısı ile şirketler yönünden riskler doğurmaktadır.
              </p>
              <p style={{ textIndent: "15px", textAlign: "justify" }}>
                Must Global olarak; sosyal güvenlik dünyasını ilgilendiren temel
                konularda, şirketlerin kanunlara uyumlu olması, bu konudaki
                risklerini doğru yönetmeleri ve zamanında, verimli ve doğru
                planlama yaparak teşvik fırsatlarını yakalayabilmeleri için
                deneyimli, teknik bilgiye sahip ve Sosyal Güvenlik mevzuatı
                uygulamaları alanında uzmanlaşmış ekibimizle sizler için
                çözümler üretmekteyiz.
              </p>
              <br></br>
              <p
                className="mb-25"
                style={{ textIndent: "15px", textAlign: "justify" }}
              >
                Uzman kadromuzla teşviklerin iş yerinize uygulanabilirliğini
                tespit ederek ve her ay sistemli şekilde teşvik takibini yaparak
                maliyetlerinizi azaltmayı hedeflemekteyiz.
              </p>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Blocks;
