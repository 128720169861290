import React from "react";

import illustration from "../../assets/img/illustration/hakkimizda.png";

function About(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        <div className="illustration-img">
          <img style={{ borderRadius: "10px",marginTop:"30px" }} src={illustration} alt="" />
        </div>
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">Hakkımızda</span>
                <h2 className="title">
                  Must Global Danışmanlık
                  <br />{" "}
                </h2>
              </div>
              <p
                className="mb-25"
                style={{ textIndent: "15px", textAlign: "justify" }}
              >
                2008 yılından itibaren Finans ve Yatırım Danışmanlığı kapsamında
                şirketlerimize profesyonel hizmet vermekteyiz.
              </p>
              <p style={{ textIndent: "15px", textAlign: "justify" }}>
                Must Global olarak esas amacımız; ticari kredi, destek, hibe vb.
                teşvik unsurları ile firmalarımızın finansman ihtiyacının
                karşılanmasına yardımcı olmak, üretim ve sermayesinin
                artırılmasına katkıda bulunmaktır. Bu sayede firmalarımızın yurt
                içi ve yurt dışında daha çok tanınmasına ve ihracat yaparak
                ülke ekonomimize fayda sağlamasına destek olmaktayız.
              </p> <br></br>
              <p style={{ textIndent: "15px", textAlign: "justify" }}>
                Bizimle birlikte büyümek için iletişime geçmenizi bekliyoruz.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default About;
