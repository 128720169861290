import React from "react";
import { Route, Switch } from "react-router-dom";
import { HashRouter } from "react-router-dom";
// Preloader
import Preloader from "./components/layouts/Preloader";
// Pages
import BakanlikDestekleri from "./components/hizmetler/bakanlikDestekleri/BakanlikDestekleri";
import Hometwo from "./components/Anasayfa/Hometwo";
import BagimsizDenetim from "./components/hizmetler/bagimsizDenetim/BagimsizDenetim";
import Hakkimizda from "./components/Hakkimizda/Hakkimizda";
import YatirimTesvikBelgesi from "./components/hizmetler/yatirimTesvikBelgesi/YatirimTesvikBelgesi";
import Kosgeb from "./components/hizmetler/kosgeb/Kosgeb";
import KdvIadesi from "./components/hizmetler/kdvIadesi/KdvIadesi";
import SgkTesvikleri from "./components/hizmetler/sgkTesvikleri/SgkTesvikleri";
import KgfVeTicariKredi from "./components/hizmetler/kgfVeTicariKredi/KgfVeTicariKredi";
import Hizmetler from "./components/Hizmetler/Hizmetler";
// import Referanslar from "./components/Referanslar/Referanslar";
import IflasVeKonkardato from "./components/hizmetler/iflasVeKonkardato/IflasVeKonkardato";
import Iletisim from "./components/İletisim/Iletisim";
import SikcaSorulanSorular from "./components/SikcaSorulanSorular/SikcaSorulanSorular";

export default () => {
  return (
    <HashRouter hashType={"noslash"}>
      <Preloader />
      <Switch>
        <Route exact path="/" component={Hometwo} />
        <Route path="/bakanlikDestekleri" component={BakanlikDestekleri} />
        <Route path="/bagimsizDenetim" component={BagimsizDenetim} />
        <Route path="/hakkimizda" component={Hakkimizda} />
        <Route path="/yatirimTesvikBelgesi" component={YatirimTesvikBelgesi} />
        <Route path="/kosgeb" component={Kosgeb} />
        <Route path="/kdvIadesi" component={KdvIadesi} />
        <Route path="/sgkTesvikleri" component={SgkTesvikleri} />
        <Route path="/kgfVeTicariKredi" component={KgfVeTicariKredi} />
        <Route path="/hizmetler" component={Hizmetler} />
        {/* <Route path="/referanslar" component={Referanslar} /> */}
        <Route path="/iflasVeKonkardato" component={IflasVeKonkardato} />
        <Route path="/iletisim" component={Iletisim} />
        <Route path="/sikcaSorulanSorular" component={SikcaSorulanSorular} />
      </Switch>
    </HashRouter>
  );
};
