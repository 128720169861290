import React from 'react'
import { Link } from 'react-router-dom'

import line1 from '../../assets/img/lines/12.png'
import line2 from '../../assets/img/lines/11.png'

const serviceblock = [
    { icon: 'flaticon-chart', title: 'Stratejik Planlama Hizmetleri' },
    { icon: 'flaticon-graph-1', title: 'Kredi ve Finans Desteği' },
    { icon: 'flaticon-diagram', title: 'Finansal Yönetim Desteği' },
    { icon: 'flaticon-money', title: 'Kazanç Yönetim Hizmetleri' },
]

function Service(props) {
    return (
        <section className="service-section shape-style-two service-line-shape section-gap grey-bg">
            <div className="container">
                {/* Section Title */}
                <div className="section-title text-center both-border mb-50">
                    <span className="title-tag">Hizmetler</span>
                    <h2 className="title">Must Global <br /> Danışmanlık</h2>
                </div>
                {/* Services Boxes */}
                <div className="row service-boxes justify-content-center">
                    {serviceblock.map((item, i) => (
                        <div key={i} className="col-lg-3 col-sm-6 col-10 wow fadeInUp" data-wow-duration="1500ms" data-wow-delay="1000ms">
                            <div className="service-box-two text-center">
                                <div className="icon">
                                    <i className={item.icon} />
                                </div>
                                <h3><Link to="/hizmetler">{item.title}</Link></h3>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="line-one">
                <img src={line1} alt="line-shape" />
            </div>
            <div className="line-two">
                <img src={line2} alt="line-shape" />
            </div>
        </section>
    );
}

export default Service;