import React from "react";
import illustration from "../../../assets/img/illustration/devlet.png";

function Content(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        <div className="illustration-img">
          <img style={{ borderRadius: "10px" }} src={illustration} alt="" />
        </div>
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">Devlet Destekleri</span>
                <h2 className="title">
                  Devlet ve Bakanlık Destekleri <br />{" "}
                </h2>
              </div>
              <p
                className="mb-25"
                style={{ textIndent: "15px", textAlign: "justify" }}
              >
                Piyasanın rekabet koşulları, artan maliyetler, pazarın giderek
                daralması gibi birçok koşul işletme sahiplerini rakiplerinin
                önüne geçmeye, her türlü devlet desteğinden en verimli şekilde
                yararlanmaya, daha inovatif ürünler ve fikirler üretmeye
                zorlamaktadır. “Doğru işletmecilik, elinde olanları kullanmayı
                bilerek kar eden işletmeciliktir” kuralından hareketle
                rakiplerinizle aynı kulvarda sizi öne geçirecek süreçler
                üretmek, işletmeniz açısından hayati önem taşımaktadır.
              </p>
              <p style={{ textIndent: "15px", textAlign: "justify" }}>
                Must Global olarak, Ticaret (Ekonomi), Sanayi ve Teknoloji,
                Tarım ve Orman Bakanlıkları, TÜBİTAK, TKDK, Kalkınma Ajansları
                gibi birçok kurum ve kuruluş nezdinde, Uygulamalı Girişimcilik,
                Arge, Tekno Yatırım, Stratejik Ürün, Yazılım, Eleman istihdamı,
                Pazar araştırma gezileri, Fuar katılımı, Katalog-Broşür,
                Danışmanlık, Eğitim, Marka-Patent Tescil Desteği gibi muhtelif
                hibe ve kredi desteklerinden maksimum tutarda yararlanabilmenizi
                sağlıyoruz.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Content;
