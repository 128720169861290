import React from "react";
import illustration from "../../../assets/img/illustration/iflas.jpg";

function Content(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        <div className="illustration-img">
          <img style={{ borderRadius: "10px",marginTop:"50px" }} src={illustration} alt="" />
        </div>
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">Konkordato</span>
                <h2 className="title">
                  Konkordato İşlemleri <br />{" "}
                </h2>
              </div>
              <p
                className="mb-25"
                style={{ textIndent: "15px", textAlign: "justify" }}
              >
                İflas anlaşması veya konkordato, batık durumdaki şirketlerin
                borçlarını karşılayabilecekleri koşullar dâhilinde ödemek için
                alacaklılarıyla mahkemelerce yaptıkları anlaşmadır.
              </p>
              <p style={{ textIndent: "15px", textAlign: "justify" }}>
                Borçlunun vadesinde ödeme yapamayacak duruma gelmesi halinde,
                borç vadesinin uzatılması veya borç ödemelerinde indirim
                yapılması, böylece borçlarını ödemek için fırsat sağlanması
                anlamına gelen konkordato, iflasa göre olumlu bir girişimdir.
              </p>
<br></br>
              <p style={{ textIndent: "15px", textAlign: "justify" }}>
                Must Global olarak; konkordato halinde geçici mühlet ve kesin mühlet sürecinde,
                durumu en iyi şekilde yönetmek için uzman ekimiz ile profesyonel hizmet vermekteyiz.
                
              </p>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Content;

