import React from "react";
import illustration from "../../../assets/img/illustration/kdv.jpg";

function About(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        <div className="illustration-img">
          <img style={{ borderRadius: "10px" }} src={illustration} alt="" />
        </div>
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">KDV İadesi</span>
                <h2 className="title">
                  KDV İadesi İşlemleri
                  <br />{" "}
                </h2>
              </div>
              <p
                className="mb-25"
                style={{ textIndent: "15px", textAlign: "justify" }}
              >
                KDV iadesi tasdik hizmeti ; KDV’den istisna ve indirimli orana
                tabi teslim ve hizmetleri bulunan mükelleflerin faaliyetleri
                dolayısıyla alış faturaları ve benzeri vesikalarda gösterilen
                ödedikleri KDV’nin, vergiye tabi işlemleri üzerinden hesaplanan
                KDV’den fazla olması hallerinde, indirim yoluyla gideremedikleri
                ve iade hakkı doğuran işlemler dolayısıyla ödedikleri KDV’nin
                Maliye Bakanlığınca tespit edilecek esaslara göre nakden veya
                mahsuben alınması hizmetidir.
              </p>
              <p style={{ textIndent: "15px", textAlign: "justify" }}>
                Must Global olarak; hizmet verdiği
                müşterilerinde üstlendiği KDV iade süreçlerini başarıyla
                yürütmüş ve pek çok farklı sektörde ve iade hakkı doğuran
                işlemde uzmanlaşmıştır. Çoğu zaman karmaşık, yorucu ve detaylı
                bürokratik süreçler içeren iade prosedürünü, sahip olduğumuz
                bilgi ve tecrübe ile sizlerin hizmetine sunmaktayız.
              </p>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default About;
