import React from 'react'
import { Link } from 'react-router-dom'

import img1 from '../../assets/img/illustration/kgf.jpg'
import img2 from '../../assets/img/illustration/ytb.png'
import img3 from '../../assets/img/illustration/sgk.jpg'
import img4 from '../../assets/img//illustration/kosgeb.png'
import img5 from '../../assets/img/illustration/kdv.jpg'
import img6 from '../../assets/img/illustration/iflas.jpg'
import img7 from '../../assets/img//illustration/devlet.png'
import img8 from '../../assets/img//illustration/denetim.jpg'


const featuresblock = [
    { img: img1, title: 'KGF ve Ticari Kredi Finansmanı', text:"" , link:"/kgfVeTicariKredi"},
    { img: img2, title: 'Yatırım Teşvik Belgesi', text: '' , link:"/yatirimTesvikBelgesi"},
    { img: img3, title: 'SGK Teşvikleri', text: '', link:"/sgkTesvikleri" },
    { img: img4, title: 'KOSGEB Hibe ve Teşvikleri', text: '' , link:"/kosgeb"},
    { img: img5, title: 'KDV İadesi İşlemleri', text: '', link:"/kdvIadesi" },
    { img: img6, title: 'Konkordato İşlemleri', text: '' , link:"/iflasVeKonkardato"},
    { img: img7, title: 'Devlet ve Bakanlık Destekleri', text: '' , link:"/bakanlikDestekleri"},
    { img: img8, title: 'Bağımsız Denetim', text: '' , link:"/bagimsizDenetim"},
]

function Features(props) {
    return (
        <section className="feature-section section-gap grey-bg">
            <div className="container">
                <div className="section-title text-center both-border mb-50">
                    <span className="title-tag"> Hizmetler</span>
                   
                </div>
                {/* Feature boxes */}
                <div className="feature-boxes row justify-content-center">
                    {featuresblock.map((item, i) => (
                        <div key={i} className="col-lg-4 col-md-6 col-10 col-tiny-12">
                            <div className="feature-box">
                                <div className="feature-bg bg-img-c" style={{ backgroundImage: "url(" + item.img + ")" }}>
                                </div>
                                <div className="feature-desc">
                                    <Link to={item.link} className="feature-link"><i className="fal fa-long-arrow-right" /></Link>
                                    <h4>{item.title}</h4>
                                    <p>{item.text}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Features;