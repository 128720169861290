import React from "react";
import illustration from "../../../assets/img/illustration/ytb.png";

function Content(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">Yatırım Desteği</span>
                <h2 className="title">
                  Yatırım Teşvik Belgesi
                  <br />
                </h2>
              </div>

              <p style={{ textIndent: "15px", textAlign: "justify" }}>
                Ülkemizde; yatırım eğiliminin devamlılığını ve sürdürülebilir
                kalkınmayı sağlamak, uluslararası rekabet gücünü artıracak
                teknoloji ve araştırma-geliştirme içeriği yüksek yatırımları
                özendirmek, yabancı yatırımları artırmak amacıyla Yatırım Teşvik
                Sistemi kurulmuştur. Yatırım Teşvik Belgesi, yatırımcıyı Yatırım
                Teşvik Sistemi içerisinde tanımlanan devlet teşvik
                uygulamalarından faydalandırarak hem ulusal hem uluslar arası
                pazarlarda rekabet edebilme gücünü yükseltir.
              </p>

              <ul className="about-list">
                <li>
                  <i /> Yatırım Teşvik Sistemi;
                </li>
                <li>
                  <i className="far fa-check" /> Genel yatırım teşvik
                  uygulamaları,
                </li>
                <li>
                  <i className="far fa-check" /> Büyük ölçekli yatırım teşvik
                  uygulamaları,
                </li>
                <li>
                  <i className="far fa-check" /> Bölge bazında yatırım
                  teşvikler,
                </li>
                <li>
                  <i className="far fa-check" /> Stratejik yatırım teşvik
                  uygulamalarından oluşur.
                </li>
              </ul>
              <p
                style={{ textIndent: "15px", textAlign: "justify" }}
                className="mb-25"
              >
                İşletmenize yönelik mevcut veya yeni başlayan projelerinizi
                teşvik mevzuatı çerçevesinde inceleyerek tüm imkânlardan
                faydalanmanızı sağlayalım.
              </p>
            </div>
          </div>
        </div>
        <div className="illustration-img">
          <img style={{ borderRadius: "10px" }} src={illustration} alt="" />
          <br></br>

          <ul className="about-list mt-3">
            <li>
              <i />
              &nbsp; Yatırım Teşvik Belgesi konusunda sunulan hizmetler;
            </li>
            <li>
              <i className="far fa-check" /> &nbsp;Yatırım Teşvik Belgesi alımı
              konusunda gerekli dokümanların hazırlanması,
            </li>
            <li>
              <i className="far fa-check" /> &nbsp;Yatırım Teşvik Belgesi
              başvurusunun yapılması,
            </li>
            <li>
              <i className="far fa-check" /> &nbsp;İlgili birimler ile
              toplantılar yaparak varsa sorunlu ve problemli konuların çözümlenmesi,
            </li>
            <li>
              <i className="far fa-check" />
              &nbsp; Yatırım Teşvik Belgesi revize ve süre uzatım başvurularının
              yapılması,
            </li>
            <li>
              <i className="far fa-check" /> &nbsp;Yatırım Teşvik Belgesi
              kapama işlemlerinin yapılması.
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}
export default Content;
