import React from "react";

import illustration from "../../../assets/img/illustration/kosgeb.png";

function Content(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        <div className="illustration-img">
          <img style={{ borderRadius: "10px",marginTop:"20px"}} src={illustration} alt="" />
        </div>
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">KOSGEB Desteği</span>
                <h2 className="title">
                  KOSGEB Hibe ve Teşvikleri <br />{" "}
                </h2>
              </div>
              <p
                className="mb-25"
                style={{ textIndent: "15px", textAlign: "justify" }}
              >
                Küçük ve Orta Ölçekli İşletmeleri Geliştirme ve Destekleme
                İdaresi Başkanlığı, Türkiye'deki küçük ve orta ölçekli
                işletmelerin ekonomideki rolünü ve etkinliğini artırmak, rekabet
                güçlerini artırmak ve sanayide entegrasyonu ekonomik gelişmelere
                uygun biçimde gerçekleştirmek amacıyla kurulmuş kamu kurumudur.
                KOSGEB, işletmelere bankalarca kullandırılan kredilerin faizini
                karşılamaktadır.
              </p>
              <p style={{ textIndent: "15px", textAlign: "justify" }}>
                Must Global olarak; KOSGEB tarafından sunulan muhtelif hibe ve
                kredi desteklerinden maksimum tutarda yararlanabilmenizi
                sağlayacak önerilerde bulunup proje süreçlerini yöneterek mali
                açıdan işletmenize destek olabilecek tüm kulvarlarda yanınızda
                yer alıyoruz.
              </p>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Content;
