import React from "react";
import illustration from "../../../assets/img/illustration/denetim.jpg";

function Content(props) {
  return (
    <section className="about-section about-illustration-img section-gap">
      <div className="container">
        <div className="illustration-img">
          <img style={{ borderRadius: "10px" }} src={illustration} alt="" />
        </div>
        <div className="row no-gutters justify-content-lg-end justify-content-center">
          <div className="col-lg-6 col-md-10">
            <div className="about-text">
              <div className="section-title left-border mb-40">
                <span className="title-tag">Bağımsız Denetim</span>
                <h2 className="title">
                  Bağımsız Denetim <br />{" "}
                </h2>
              </div>
              <p
                className="mb-25"
                style={{ textIndent: "15px", textAlign: "justify" }}
              >
                Bağımsız denetim, işletmelerin yıllık finansal tablo ve diğer
                finansal bilgilerinin genel kabul görmüş bağımsız denetim
                teknikleri uygulanarak, TFRS/IFRS gibi ulusal ve uluslararası
                finansal raporlama standartlarına uygun olacak şekilde
                denetlenmesi ve değerlendirilerek rapora bağlanmasını ifade
                eder.
              </p>
              <p style={{ textIndent: "15px", textAlign: "justify" }}>
                Küreselleşen dünyada parasal hareketlerdeki artış,
                anlaşılabilir, karşılaştırılabilir ve şeffaf finansal tablo
                sunum ihtiyacını beraberinde getirmiştir. Bu nedenle ortak
                raporlama çerçevesiyle sunulan raporlar ile karar alıcılar daha
                doğru karşılaştırma yapabilmektedir. Bilginin kalitesini
                arttırmak için yapılan Bağımsız Denetim, bir güvence hizmetidir.
              </p> <br></br>

              <p style={{ textIndent: "15px", textAlign: "justify" }}>
                Must Global olarak; şirketlerimize günümüz denetim tekniklerini şeffaf bir şekilde uygulayarak Bağımsız Denetim hizmeti sunmaktayız.
              </p>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Content;
