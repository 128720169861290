import React, { Component, } from "react";

import illustration from "../../assets/img/illustration/mustcontact.png";

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      alert: "",
    };
  }

  handlePost(e) {
    e.preventDefault();
    if (this.state.name && this.state.email && this.state.message) {
      this.setState({
        alert: "Mesajınız gönderiliyor, lütfen bekleyiniz...",
      });
      try {
        const url = "https://api.mustdanismanlik.com/api/Mail";
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            message: this.state.message,
          }),
        };
        return fetch(url, options).then(() => {
          this.setState({ alert: "Mesajınız gönderildi, teşekkür ederiz!" });
        });
      } catch (error) {
        return this.setState({
          alert:
            "Mesajınız gönderilemedi, lütfen tekrar deneyiniz ya da sol tarafta bulunan diğer iletişim yöntemlerini deneyiniz.",
        });
      }
    } else {
      return this.setState({
        alert: "Lütfen gerekli tüm alanları doldurunuz!!!",
      });
    }
  }

  sendMail({ mailSubject, mailMessage }) {
    return (window.location.href =
      "mailto:" +
      "info@mustdanismanlik.com" +
      "?subject=" +
      encodeURIComponent(mailSubject) +
      "&body=" +
      encodeURIComponent(mailMessage));
  }

  render() {
    return (
      <section className="contact-section contact-page section-gap-top">
        <div className="container" style={{ paddingTop: "103px" }}>
          <div className="contact-info">
            <div className="row justify-content-center">
              <div className="col-lg-6 order-2 order-lg-1">
                <div className="illustration-img text-center">
                  <img className="rounded-lg" src={illustration} alt="" />
                </div>
              </div>
              <div className="col-lg-6 col-md-10 order-1 order-lg-2">
                <div className="contact-info-content">
                  <div className="section-title left-border mb-40">
                    <span className="title-tag">İletişime Geçin</span>
                    
                  </div>
                  <p>
                    Must Global olarak şirketinizi en üst seviyeye taşımak için
                    iletişime geçmenizi bekliyoruz
                  </p>
                  <ul>
                    <li >
                      <a
                        className="text-muted contactIcon  "
                        target="_blank"
                        rel="noopener noreferrer"
                        href="tel:+905454545434"
                      >
                        <i className="far fa-phone" />
                          +90 545 454 5434
                      </a>
                      
                    </li>
                    <li >
                      <a
                        className="text-muted contactIcon "
                        target="_blank"
                        rel="noopener noreferrer"
                        href={
                          "https://wa.me/" +
                          "+905454545434" +
                          "?text=" +
                          "Ürünlerinizle%20ilgileniyorum.%20Lütfen%20benimle%20iletişime%20geçer%20misiniz?"
                        }
                      >
                        <i className="fab fa-whatsapp" />
                          +90 545 454 5434
                      </a>
                      
                    </li>
                    <li>
                      <a
                        className="text-muted contactIcon"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://instagram.com/must_global_danismanik?igshid=YmMyMTA2M2Y="
                      >
                        <i className="fab fa-instagram" />
                        instagram/seyfettinuslu
                      </a>
                      
                    </li>
                    <li>
                      <span
                        className="text-muted contactIcon cursorPointer"
                        style={{cursor:"pointer"}}
                        onClick={() =>
                          this.sendMail({
                            mailSubject: "Tanışma",
                            mailMessage:
                              "Merhaba Must Danışmanlık, \n \n Sizinle tanışmak istiyorum. \n Lütfen benimle iletişime geçiniz. \n \n Saygılarımla, \n",
                          })
                        }
                      >
                        <i className="far fa-envelope-open" />
                        info@mustdanismanlik.com
                      </span>
                    </li>
                    <li>
                      <span className="text-muted contactIcon">
                        <a
                          href="https://www.google.com/maps/place/%C3%9Cmit,+2494.+Sk.+No:68,+06810+Yenimahalle%2FAnkara/@39.8912008,32.6996769,233m/data=!3m1!1e3!4m5!3m4!1s0x14d3388c19c5e3fb:0xb3e2402a20ccf7a0!8m2!3d39.8911863!4d32.7005332"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="far fa-map-marker-alt" />
                            Ümitköy/Ankara
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-form grey-bg">
            <div className="row no-gutters justify-content-center">
              <div className="col-10">
                <div className="section-title text-center mb-40">
                  <h2 className="title">Bizimle İletişime Geçin</h2>
                </div>
                <form action="#">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="input-group mb-30">
                        <input
                          type="text"
                          placeholder="Ad Soyad"
                          onChange={(e) => {
                            this.setState({ name: e.target.value });
                            this.setState({ alert: "" });
                          }}
                        />
                        <span className="icon">
                          <i className="far fa-user-circle" />
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="input-group mb-30">
                        <input
                          type="email"
                          placeholder="Mail Adresi"
                          onChange={(e) => {
                            this.setState({ email: e.target.value });
                            this.setState({ alert: "" });
                          }}
                        />
                        <span className="icon">
                          <i className="far fa-envelope-open" />
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="input-group mb-30">
                        <input
                          type="text"
                          placeholder="Telefon"
                          onChange={(e) => {
                            this.setState({ phone: e.target.value });
                            this.setState({ alert: "" });
                          }}
                        />
                        <span className="icon">
                          <i className="far fa-phone" />
                        </span>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="input-group textarea mb-30">
                        <textarea
                          placeholder="Mesajınızı yazınız"
                          defaultValue={""}
                          onChange={(e) => {
                            this.setState({ message: e.target.value });
                            this.setState({ alert: "" });
                          }}
                        />
                        <span className="icon">
                          <i className="far fa-pencil" />
                        </span>
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      <button
                        type="submit"
                        className="main-btn"
                        onClick={(e) => this.handlePost(e)}
                      >
                        Gönder
                      </button>
                    </div>
                  </div>
                  {this.state.alert && (
                    <div>
                      <div className="form-message-warning mt-4" />
                      <div className="form-message-success">{this.state.alert}</div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid container-1600">
        </div>
      </section>
    );
  }
}

export default Content;
